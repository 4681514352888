import axios from 'axios';
const CenterFormApi = axios.create({
  baseURL:
    // 'http://centerform.local:3013'
  'https://back-magicdiet.kwantic.fr' // //,
});

CenterFormApi.interceptors.request.use(
  config => {
    if (localStorage.getItem('TOKEN')) {
      config.headers.common['Authorization'] =
        'Bearer ' + localStorage.getItem('TOKEN');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

CenterFormApi.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.message.indexOf(401) !== -1) {
      localStorage.removeItem('TOKEN');
      window.location = '/login';
    }

    return Promise.reject(error);
  },
);

export default CenterFormApi;
