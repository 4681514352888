import React from 'react';

const Input = ({label, icon, value, onClick, ref, ...props}) => {
  return (
    <div onClick={onClick} className="ecs-group">
      <input {...props} ref={ref} value={value} />
      <span className="ecs-highlight"></span>
      <span className="ecs-bar"></span>
      <label>
        {label} {icon ? <span className={icon} /> : null}
      </label>
    </div>
  );
};
export default Input;
