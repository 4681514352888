import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import React, {Suspense, lazy, Fragment, useContext} from 'react';
import {MySpinner} from 'components/atoms';
import {ToastContainer} from 'react-toastify';
import {AuthContext} from 'services';
const Collaborators = lazy(() => import('pages/Collaborators/'));
const CreateCollaborator = lazy(() =>
  import('pages/Collaborators/NewCollaborator'),
);
const ViewCollaborator = lazy(() =>
  import('pages/Collaborators/ViewCollaborator'),
);
const EditCollaborator = lazy(() =>
  import('pages/Collaborators/EditCollaborator'),
);
const StandardUsers = lazy(() => import('pages/StandardUsers/'));
const CollaboratorClients = lazy(() => import('pages/CollaboratorClients/'));
const Meals = lazy(() => import('pages/Meals/'));
const CreateMeal = lazy(() => import('pages/Meals/CreateMeal'));
const EditMeal = lazy(() => import('pages/Meals/EditMeal'));
const UserProfile = lazy(() => import('pages/UserProfile/'));
const PhysicalUsers = lazy(() => import('pages/PhysicalUsers/'));
const PremiumUsers = lazy(() => import('pages/PremiumUsers/'));
const Diagnosis = lazy(() => import('pages/User/Diagnosis'));
const CreateUser = lazy(() => import('pages/User/NewUser'));
const EditUser = lazy(() => import('pages/User/EditUser'));
const CalendarView = lazy(() => import('pages/Calendar'));
const Fruits_Vegtables = lazy(() => import('pages/Meals/Fruits_Vegtables'));
const PricesPlans = lazy(() => import('pages/Plans'));

const ProductsScreen = lazy(() => import('pages/Products'));
const AppMain = () => {
  const CenterFormContext = useContext(AuthContext);
  const {user_data} = CenterFormContext.state;
  return (
    <Fragment>
      {/* Components */}

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/clients/:id" component={CollaboratorClients} />
        </Switch>
        <Switch>
          <Route exact path="/standardUsers" component={StandardUsers} />
          <Route
            exact
            path="/standardUsers/create"
            render={props => <CreateUser {...props} type={'standard'} />}
          />

          <Route exact path="/standardUsers/:id" component={UserProfile} />
          <Route
            exact
            path="/standardUsers/:id/diagnostic"
            render={props => <Diagnosis {...props} type={'standard'} />}
          />
          <Route
            exact
            path="/standardUsers/:id/edit"
            render={props => <EditUser {...props} type={'standard'} />}
          />
        </Switch>
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/calendar/" component={CalendarView} />
        </Switch>
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/products" component={ProductsScreen} />
        </Switch>
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/prices-plan" component={PricesPlans} />
        </Switch>
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/physicalUsers" component={PhysicalUsers} />
          <Route
            path="/physicalUsers/create"
            render={props => <CreateUser {...props} type={'physical'} />}
          />
          <Route exact path="/physicalUsers/:id" component={UserProfile} />
          <Route
            exact
            path="/physicalUsers/:id/diagnostic"
            render={props => <Diagnosis {...props} type={'physical'} />}
          />
          <Route
            exact
            path="/physicalUsers/:id/edit"
            render={props => <EditUser {...props} type={'physical'} />}
          />
        </Switch>
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/premiumUsers" component={PremiumUsers} />
          <Route
            path="/premiumUsers/create/:colId"
            render={props => <CreateUser {...props} type={'premium'} />}
          />

          <Route
            path="/premiumUsers/create"
            render={props => <CreateUser {...props} type={'premium'} />}
          />
          <Route exact path="/premiumUsers/:id" component={UserProfile} />
          <Route
            exact
            path="/premiumUsers/:id/diagnostic"
            render={props => <Diagnosis {...props} type={'premium'} />}
          />
          <Route
            exact
            path="/premiumUsers/:id/edit"
            render={props => <EditUser {...props} type={'premium'} />}
          />
        </Switch>
      </Suspense>

      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/collaborateurs" component={Collaborators} />
          <Route
            exact
            path="/collaborateurs/create"
            component={CreateCollaborator}
          />
          <Route
            exact
            path="/collaborateurs/:id"
            component={ViewCollaborator}
          />
          <Route
            exact
            path="/collaborateurs/:id/edit"
            component={EditCollaborator}
          />
        </Switch>
      </Suspense>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <MySpinner active={true} />
            </div>
          </div>
        }
      >
        <Switch>
          <Route
            exact
            path="/meals"
            render={props => <Meals {...props} type={'standard'} />}
          />
          <Route
            exact
            path="/meals-premium"
            render={props => <Meals {...props} type={'premium'} />}
          />

          <Route path="/meals/fruits-vegtables" component={Fruits_Vegtables} />
          <Route
            exact
            path="/meals/createMeal"
            render={props => <CreateMeal {...props} type={'standard'} />}
          />
          <Route
            exact
            path="/meals-premium/createMeal"
            render={props => <CreateMeal {...props} type={'premium'} />}
          />

          <Route
            path="/meals/:id"
            render={props => <EditMeal {...props} type={'standard'} />}
          />
          <Route
            exact
            path="/meals-premium/:id"
            render={props => <EditMeal {...props} type={'premium'} />}
          />
        </Switch>
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/standardUsers" />} />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
