import React, {forwardRef} from 'react';
import Input from './Input';

const DateCustomInput2 = forwardRef(({value, onClick, label}, ref) => (
  <Input
    value={value}
    onClick={onClick}
    ref={ref}
    icon="icon-Vector"
    label={label}
    className=""
    type="text"
    required
  />
));

export default DateCustomInput2;
