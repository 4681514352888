import React, {useEffect, useContext} from 'react';
import history from 'services/history';
import {Router, Switch, Route} from 'react-router-dom';
import {AuthContext} from 'services';
import LogIn from 'pages/login';
import Main from 'pages/Main';
import 'react-datepicker/dist/react-datepicker.css';
const App = () => {
  const targetContext = useContext(AuthContext);
  const {loggedIn, user_data} = targetContext.state;

  const {getLoginState} = targetContext;

  useEffect(() => {
    getLoginState();
  }, []);
  return (
    <Router history={history}>
      <Switch>
        {loggedIn && user_data ? <Main /> : null}

        <Route exact path="/login" render={props => <LogIn {...props} />} />
      </Switch>
    </Router>
  );
};

export default App;
