import React, {useContext, useState, Fragment} from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import './index.css';
import {AuthContext} from 'services';
import {
  Card,
  CardBody,
  Container,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';
import centerFormLogo from 'assets/images/logo.png';
import history from 'services/history';
const LogIn = () => {
  const targetContext = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const login = async e => {
    e.preventDefault();
    const {signIn, getLoginState} = targetContext;
    setLoading(true);
    const res = await signIn({email, password});
    setInvalid(!res);
    setLoading(false);
    if (res) {
      const user = await getLoginState();
      if (user.type === 'admin') {
        return history.push('/standardUsers');
      } else {
        return history.push(`/collaborateurs/${user._id}`);
      }
    }
  };
  return (
    <Fragment>
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        <div className="loginContainer">
          <Container>
            <img src={centerFormLogo} alt="logo" />
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Veuillez vous connecter</CardTitle>
                <Form onSubmit={login}>
                  <FormGroup>
                    <Label>Adresse e-mail</Label>
                    <Input
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      placeholder="Enter your E-mail address"
                      invalid={invalid}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Mot de passe</Label>
                    <Input
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      placeholder="Enter your password "
                      invalid={invalid}
                      required
                    />
                    <FormFeedback>
                      L'e-mail ou le mot de passe ne sont pas valides, réessayez
                    </FormFeedback>
                  </FormGroup>
                  <Button
                    disabled={loading}
                    block
                    className="mb-2 mt-4 mr-2 submitButton"
                    size="lg"
                  >
                    {loading ? (
                      <span style={{marginRight: '.5rem'}}>
                        <Spinner
                          as="span"
                          variant="warning"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="grow"
                        />{' '}
                      </span>
                    ) : null}
                    Connexion
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  );
};

export default LogIn;
