import React, {useState, useRef, useEffect} from 'react';

const DropDown = ({
  label,
  icon,
  value,
  onClick,
  ref,
  disabled,
  data,
  readOnly = 'readOnly',
  onChange,
  ...props
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const dropRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const [searchQuery, setSearchQuery] = useState();

  const handleClickOutside = event => {
    if (dropRef && !dropRef.current?.contains(event.target)) {
      setShowDropDown(false);
    }
  };
  useEffect(() => {
    setSelectedValue(value);

    if (!showDropDown) {
      return;
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
  }, [showDropDown, value]);

  const onValueSelect = (e, item) => {
    e.stopPropagation();

    setShowDropDown(false);
    if (onClick) {
      onClick(item);
    }
  };
  const onInputChange = (e) => {
    setSearchQuery(e.target.value);
    onChange(e.target.value);
  }
  return (
    <div
      ref={dropRef}
      onClick={() => (!disabled ? setShowDropDown(true) : null)}
      className="ecs-group dropdown-center"
    >
      <input
        placeholder=" "
        readOnly={readOnly}
        disabled={disabled}
        {...props}
        ref={ref}
        onChange={onInputChange}
        value={
         searchQuery ?? selectedValue?.name ?? selectedValue ?? ''
        }
      />
      <span className="ecs-highlight"></span>
      <span className="ecs-bar"></span>
      <label>
        {label} {icon ? <span className={icon} /> : null}
      </label>
      {showDropDown ? (
        <ul className="ecs-dropdown">
          {data.map((item, index) => (
            <li
              key={item.name}
              className={`${value === item ? 'selected-active' : ''}`}
              onClick={e => {onValueSelect(e, item); setSearchQuery(); onChange?onChange():()=>{}}}
            >
              {item.name ?? `${item.firstName} ${item.lastName}`}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
export default DropDown;
