import CreateContext from './CreateContext';
import CenterFormApi from '../api/CenterFormApi';
import history from 'services/history';
const authReducer = (state, action) => {
  switch (action.type) {
    case 'signIn_error':
      return {...state, errorMessage: action.payload, loading: false};
    case 'signIn':
      return {loggedIn: action.payload};
    case 'userData':
      return {...state, user_data: action.payload, loading: false};
    default:
      return state;
  }
};

const signIn =
  dispatch =>
  async ({email, password}) => {
    try {
      const response = await CenterFormApi.post('/login', {email, password});
      dispatch({type: 'signIn', payload: true});
      CenterFormApi.defaults.headers.common['Authorization'] =
        'Bearer ' + response.data.token;
      localStorage.setItem('TOKEN', response.data.token);
      return true;
    } catch (err) {
      return false;
    }
  };
const getLoginState = dispatch => async () => {
  const token = localStorage.getItem('TOKEN');

  if (token) {
    dispatch({type: 'signIn', payload: true});
    CenterFormApi.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    const response = await CenterFormApi.get('/getUserData');
    dispatch({type: 'userData', payload: response.data});
    return response.data;
  } else {
    history.push('/login');
  }
};

const signOut = dispatch => async () => {
  dispatch({type: 'signIn', payload: false});
  localStorage.removeItem('TOKEN');
  CenterFormApi.defaults.headers.common = {};
  history.push('/login');
};

export const {AllProvider, Context} = CreateContext(
  authReducer,
  {signIn, getLoginState, signOut},
  {loggedIn: false, errorMessage: ''},
);
